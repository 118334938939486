<script setup lang="ts">
import { KIOE } from '@/utility/utility'

const contentwrapel = ref<HTMLElement | null>()

const routeClass = ref('')

const mode = 'mode-1'

const route = useRoute()

const routeName = computed(() => route.name)

watch(
  route,
  (toRoute, fromRoute) => {
    switch (String(toRoute)) {
      case 'session-overview':
        if (fromRoute && KIOE(fromRoute, 'name') && fromRoute.name === 'session-values') {
          setTimeout(() => {
            routeClass.value = String(toRoute)
          }, 300)
        }
        else if (fromRoute && KIOE(fromRoute, 'name') && fromRoute.name === 'session-tais') {
          setTimeout(() => {
            routeClass.value = String(toRoute)
          }, 300)
        }
        else if (fromRoute && KIOE(fromRoute, 'name') && fromRoute.name === 'session-columnID') {
          setTimeout(() => {
            routeClass.value = String(toRoute)
          }, 300)
        }
        else {
          routeClass.value = String(toRoute)
        }
        break

      case 'session-columnID':
      case 'session-tais':
      case 'session-values':
        if (fromRoute && KIOE(fromRoute, 'name') && fromRoute.name === 'session-overview') {
          setTimeout(() => {
            routeClass.value = String(toRoute)
          }, 300)
        }
        else {
          routeClass.value = String(toRoute)
        }
        break

      default:
        setTimeout(() => {
          routeClass.value = String(toRoute)
        }, 200)
        break
    }
  },
  { immediate: true },
)
</script>

<template>
  <div id="primary-content-wrapper" class="primary-content-wrapper" :class="routeName">
    <div id="content-wrap" ref="contentwrapel" class="content-wrap" :class="`mode-${mode}`">
      <slot />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/assets/scss/mq.scss';

.primary-content-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  background-position: right bottom;
  background-repeat: no-repeat;
  background-size: contain;

  >.content-wrap {
    position: absolute;
    width: 100%;
    height: 92%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    // Modes
    max-width: 90%;

    @include mq($from: dm) {
      max-width: 85%;
    }

    .template-page {
      height: 100%;
    }

  }

  &.session-overview {
    >.content-wrap {
      max-width: 100%;
      height: 100%;
    }
  }

  &.index {
    overflow: hidden;
  }

  &.sessions {
    >.content-wrap {
      max-width: 95%;
      left: auto;
      right: 0;
      transform: translate(0, -50%);
    }
  }

}
</style>
