<script setup lang="ts">

</script>

<template>
  <svg class="demartini-logo" viewBox="0 0 380 34" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M202.419 16.9422V2.47065H207.232C211.995 2.47065 214.193 5.76798 214.193 9.69812C214.193 13.6949 211.978 16.9256 207.215 16.9256C206.149 16.8257 205.333 16.6924 205.333 16.6924C202.952 16.2262 204.451 18.1246 204.451 18.1246L215.842 33.3123H224.884V32.1632C221.604 31.7802 217.923 29.0324 214.693 25.2022L209.33 18.9073C215.509 18.2412 219.072 14.4609 219.072 9.69812C219.072 4.60225 214.909 0.438965 207.299 0.438965H192.844V1.58803C197.39 1.58803 198.039 2.454 198.039 7.94954V25.7518C198.039 31.2307 197.39 32.1632 192.844 32.1632V33.3123H207.632V32.1632C203.085 32.1632 202.419 31.2307 202.419 25.7518V16.9422Z" fill="white" />
    <path d="M48.4773 33.3127H75.3222L75.7552 26.0186H74.6061L74.3396 27.1177C73.6236 30.0154 73.024 31.281 67.9781 31.281H63.2819C58.7356 31.281 58.0695 30.3984 58.0695 24.9195V16.926H60.8173C66.2962 16.926 67.1621 17.5755 67.1621 22.1884H68.3112V9.64855H67.1621C67.1621 14.2448 66.2795 14.9109 60.8173 14.9109H58.0695V2.48769H66.1796C71.2089 2.48769 71.7584 3.75333 72.5244 6.60102L72.7909 7.70013H73.94L73.4404 0.472656H48.4606V1.62172C53.0069 1.62172 53.6564 2.50434 53.6564 7.98323V25.7855C53.6564 31.2643 53.0069 32.1969 48.4606 32.1969V33.3127H48.4773Z" fill="white" />
    <path d="M99.003 6.25135L110.61 32.9797C111.027 33.829 112.126 33.6791 112.409 32.9797L123.25 7.90002V25.7522C123.25 31.2311 122.601 32.1637 118.054 32.1637V33.3127H132.842V32.1637C128.246 32.1637 127.58 31.2311 127.58 25.7522V7.94998C127.58 2.47109 128.229 1.58847 132.842 1.58847V0.456055H124.249L112.709 27.1344L101.085 0.456055H91.7755V1.60512C96.3218 1.60512 96.988 2.48774 96.988 7.96663V25.7689C96.988 31.2477 96.3218 32.1803 91.7755 32.1803V33.3294H104.265V32.1803C99.7191 32.1803 99.003 31.2477 99.003 25.7689V6.25135Z" fill="white" />
    <path d="M167.131 19.9068H155.674L161.369 6.70083L167.131 19.9068ZM154.808 21.9218H168.064L170.695 28.0002C172.011 31.0144 170.978 32.1635 166.432 32.1635V33.3126H181.603V32.1635C177.056 32.1635 176.773 31.2309 175.091 27.4007L163.401 0.522512C163.084 -0.176921 162.152 -0.160267 161.835 0.489206L150.212 27.4007C148.513 31.2309 148.246 32.1635 143.634 32.1635V33.3126H156.456V32.1635C151.91 32.1635 150.878 30.9478 152.177 28.0002L154.808 21.9218Z" fill="white" />
    <path d="M268.449 0.456055H235.192L234.693 7.85006H235.842L236.108 6.75095C236.874 3.8533 237.424 2.58766 242.453 2.58766H249.631V25.7522C249.631 31.2311 248.915 32.1637 244.368 32.1637V33.3127H259.206V32.1637C254.66 32.1637 253.944 31.2311 253.944 25.7522V2.58766H261.121C266.167 2.58766 266.717 3.8533 267.483 6.75095L267.766 7.85006H268.915L268.449 0.456055Z" fill="white" />
    <path d="M297.758 33.3127V32.1637C293.212 32.1637 292.546 31.2311 292.546 25.7522V7.96663C292.546 2.48774 293.212 1.60512 297.758 1.60512V0.456055H282.97V1.60512C287.517 1.60512 288.183 2.48774 288.183 7.96663V25.7689C288.183 31.2477 287.533 32.1803 282.97 32.1803V33.3294H297.758V33.3127Z" fill="white" />
    <path d="M342.872 26.5349L321.839 0.456055H314.112V1.60512C318.658 1.60512 319.324 2.48774 319.324 7.96663V25.7689C319.324 31.2477 318.675 32.1803 314.112 32.1803V33.3294H326.602V32.1803C322.055 32.1803 321.339 31.2477 321.339 25.7689V6.70099L343.122 33.6291C343.738 34.3452 344.854 33.9622 344.904 33.013V7.94998C344.904 2.47109 345.62 1.58847 350.166 1.58847V0.456055H337.676V1.60512C342.222 1.60512 342.872 2.48774 342.872 7.96663V26.5349Z" fill="white" />
    <path d="M379.043 33.3127V32.1637C374.496 32.1637 373.847 31.2311 373.847 25.7522V7.96663C373.847 2.48774 374.513 1.60512 379.043 1.60512V0.456055H364.255V1.60512C368.801 1.60512 369.45 2.48774 369.45 7.96663V25.7689C369.45 31.2477 368.801 32.1803 364.255 32.1803V33.3294H379.043V33.3127Z" fill="white" />
    <path d="M9.59222 2.47109V11.2473L13.3059 6.26801C13.8221 5.56857 13.9387 5.63519 13.589 6.43454L10.4415 13.7619L18.6682 10.0982C19.4509 9.74852 19.5342 9.86509 18.8347 10.3813L11.5573 15.6937L22.3485 16.6429C23.2145 16.7262 23.2145 16.8761 22.3485 16.976L11.5406 17.9252L18.8181 23.2209C19.5175 23.7372 19.4342 23.8537 18.6515 23.504L10.4249 19.8403L13.589 27.2177C13.9387 28.0004 13.8221 28.0836 13.3059 27.3842L9.57557 22.3716V24.9695C9.57557 30.4484 10.1751 31.331 14.6714 31.331C25.5793 31.331 27.8774 23.3375 27.8774 16.926C27.8774 10.5146 25.5793 2.52105 14.8879 2.52105H9.59222V2.47109ZM0 33.3127V32.1637C4.54631 32.1637 5.19579 31.2311 5.19579 25.7522V7.94998C5.19579 2.47109 4.52966 1.58847 0 1.58847V0.456055H14.838C27.5943 0.456055 33.1398 7.85006 33.1398 16.8927C33.1398 25.9354 27.6109 33.3294 14.838 33.3294H0V33.3127Z" fill="white" />
  </svg>
</template>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
.demartini-logo {
    * {
        fill: $dm-blue;
    }
}
</style>
